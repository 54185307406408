.gameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gameInfo {
    margin-bottom: 10px;
  }
  
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gameArea {
    position: relative; /* This is crucial since its children are absolutely positioned */
    width: 400px;
    height: 500px;
    border: 3px solid #000;
    margin-bottom: 20px;
    overflow: hidden; /* This ensures game objects don't spill outside the boundary */
  }
  .gameArea {
    position: relative; /* This is crucial since its children are absolutely positioned */
    width: 400px;
    height: 500px;
    border: 3px solid #000;
    margin-bottom: 20px;
    overflow: hidden; /* This ensures game objects don't spill outside the boundary */
    background-image: url('/public/images/mfergalaga/bg.jpg'); /* Add your image path here */
    background-size: cover; /* This will cover the entire area */
    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
    background-position: center; /* This ensures the image is centered */
}

  
  .gameArea::after {
    content: "";
    display: block;
    clear: both;
  }
  
  .instructions {
    font-weight: bold;
  }
  
  .instructions span {
    text-decoration: underline;
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it's on top */
}

.modal-content {
    width: 80%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff3333; /* Red color */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.modal-button:hover {
    background-color: #e60000;
}

.controlContainer {
    position: relative; /* This ensures child elements are positioned relative to this container */
    width: 400px; /* Adjust if needed */
    height: 200px; /* Adjust based on your layout needs */
}

.controlPad {
    position: absolute; /* This positions the pads relative to the controlContainer */
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 80px;
    font-weight: bold;
    font-size: 24px;
    color: black;
}

.leftControl {
    left: 10px;
    top: 0;
}

.rightControl {
    left: 100px;
    top: 0;
}

.shootControl {
    right: 10px;
    top: 0;
}
.startButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
}
