.game-container {
    padding: 2em;
    max-width: 400px;
    margin: 0 auto;
}

.title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 1em;
}

section {
    margin-bottom: 2em;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.games-list {
    width: 100%;
    border-collapse: collapse;
}

.create-game-button {
    width: 250px;
}

input[type="text"] {
    margin-left: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    min-width: 150px;
    max-width: 250px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-family: 'Sartoshi';
}