.grid-container {
    display: flex;
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;
}

.cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #000;
    position: relative;
}

.cell-img {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
}
