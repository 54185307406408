/* Style for each card */

.card {
    width: 150px;
    height: 200px;
    border: 1px solid #000;
    border-radius: 8px;
    background-color: white;
    margin: 5px;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    /* change cursor to a hand when it’s over the card */
}

.cardImage {
    height: 40%;
    /* 40% of the card's height */
    overflow: hidden;
    /* Ensure the image doesn't spill out */
    padding: 2.5%;
    /* Add padding to create a boxed effect */
    border: 1px solid #000;
    /* Add a border to the image box */
    box-sizing: border-box;
    /* Ensures padding and border are included in width/height calculations */
    margin: 0 auto;
    /* Center the image box horizontally */
}

.cardImage img {
    width: 100%;
    /* Take the full width of the container */
    height: 100%;
    /* Take the full height of the container */
    object-fit: cover;
    /* Make sure the image covers the space without distorting */
    display: block;
    /* Removes any default vertical spacing */
}

.cardTitle {
    font-size: 0.9em;
}

.cardText {
    font-size: 0.6em;
}

.card.green {
    background-color: green;
}

.card.red {
    background-color: red;
}

.card.black {
    background-color: black;
    color: white;
}

.card.blue {
    background-color: blue;
}

.card.white {
    background-color: white;
    color: black;
}

/* Style for the back of the card */
.card-back {
    border-radius: 8px;
    background-color: #2B547E;
    background-image: url('/public/images/castle2.png');
    /* Replace with the path to your logo image */
    background-position: center;
    /* Center the logo */
    background-repeat: no-repeat;
    /* Do not repeat the logo */
    background-size: 50%;
    /* Make sure your logo is fully visible within the card */
}

/* Style for the back of the card */
.card-empty {
    border-radius: 8px;
    background-color: #ffffff;
}