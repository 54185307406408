.landingPageContainer {
    text-align: center;
    font-size: 30px;
}
.landingPageContainer button {
    font-size: 40px; /* or whatever size you prefer */
}


.games-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Makes a 2 column grid */
    gap: 1rem; /* Spacing between grid items */
}
/* Mobile view: 1 column grid */
@media (max-width: 768px) {
.games-container {
    grid-template-columns: 1fr;
}
}
/* Mobile view: 1 column grid */
@media (max-width: 768px) {
.games-container {
    grid-template-columns: 1fr;
}
}
.header-content {
    display: flex;
    align-items: center; /* Aligns items on the cross axis */
    justify-content: center;
}
.game-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.logo-image {
    height: 60px; /* You can set it as per your need */
    width: 60px;  /* You can set it as per your need */
    margin-right: 10px; /* Space between logo and heading */
}  
.game-item img {
    width: 300px;  /* Fixed width */
    height: 150px; /* Fixed height */
    object-fit: cover; /* This will ensure that the image covers the entire size without distortion */
    border-radius: 8px; /* Optional: Round the edges for a better visual */
    overflow: hidden; /* This will hide any overflowing part of the image */
    border: 1px solid black;
    cursor: pointer;
}

.game-item p {
    margin-top: 0.5rem; /* Some spacing between the image and the text */
}
