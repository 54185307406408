.captured-table {
    width: 400px;
}

.captured-table img {
    width: 30px;
    height: 30px;
}

.captured-table th {
    text-align: left;
}