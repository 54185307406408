.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  background-color: #FEAC64;
  font-family: 'Sartoshi', sans-serif;
}

@font-face {
  font-family: 'Sartoshi';
  src: local('Sartoshi'),
    url('./assets/fonts/SartoshiScript-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px;
  font-family: 'Sartoshi', sans-serif;
}

button:disabled {
  background-color: grey;
  color: black;
  cursor: not-allowed;
}

.depress-button {
  transition: all 0.2s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 4px;
}

.depress-button:active {
  box-shadow: none;
  transform: translateY(2px);
}

.App-link {
  color: #61dafb;
}