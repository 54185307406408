.fighting-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.fighting-game-canvas {
    border: 2px solid black;
    background-color: #d3d3d3;
}
